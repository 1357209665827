<template>
    <v-dialog
            v-model="dialog"
            max-width="450"
            permanent
        >
            <v-card>
                <v-card-title
                    class="headline red lighten-2"
                    primary-title
                >
                    Cambiar contraseña
                </v-card-title>

                <v-card-text >
                    <h3 class="my-5">({{data.username}}) <span>{{data.nombre}}</span></h3>

                    <v-form
                        ref="passwdForm"
                        v-model="validPasswd"
                    >
                        <v-text-field
                            v-model="passwd1"
                            type="password"
                            rounded
                            outlined
                            :rules="[v => !!v || 'Anote una contraseña']"
                        ></v-text-field>

                        <v-text-field
                            v-model="passwd2"
                            type="password"
                            rounded
                            outlined
                            :rules="[v => v===passwd1 || 'No coinciden']"
                        ></v-text-field>
                    </v-form>

                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text
                        color="error"
                        @click="cancela"
                    >
                        Cancelar
                    </v-btn>
                    
                    <v-btn
                        :disabled="!validPasswd"
                        color="success"
                        
                        @click="actualiza"
                    >
                        Actualizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>
export default {
    props:{
        dialogPass:Boolean,
        data:Object
    },
    data:()=>({
        passwd1:'',
        passwd2:'',
        validPasswd:false
    }),
    methods:{
        async actualiza(){
            let params ={
                id:this.data.id,
                password: this.passwd1,
            }
            try{
                let pa = await this.$http({
                    url:'/usuarios/changepassword',
                    method:'POST',
                    data:params
                })
                if (pa.data.estado == 'true'){
                    this.$refs.passwdForm.reset()
                    this.dialog = false
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Cambiado',
                        text: 'Contraseña cambiada exitosamente',
                    })
                } else {
                    console.log(pa.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede actualizar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }

            
            this.dialog = false
        },
        cancela(){
            this.$refs.passwdForm.reset()
            this.dialog = false
        }
    },
    computed:{
        dialog:{
            get(){
                return this.dialogPass
            },
            set(val){
                //console.log(val)
                this.$emit('update:dialogPass',val)
            },


        },
    },
}
</script>

<style>

</style>