<template>
    <div>
        <div>
            <span class="font-weight-black">{{etiqueta}}:</span>
            <v-icon small v-if="!editando" @click="edita">mdi-pencil</v-icon>
            <v-icon small v-if="editando" color="green" @click="guarda">mdi-check-bold</v-icon>
            <v-icon small v-if="editando" color="red" @click="cancela">mdi-close-circle</v-icon>
        </div>
        
        <div v-if="!editando">
            {{dato}}
        </div>
        <div v-if="editando">
            <v-text-field
                dense
                hide-details
                v-model="esteDato"
            />
        </div>
    </div>
</template>

<script>
export default {
    props:{
        dato:String,
        etiqueta:String,
        campo:String
    },
    data:()=>({
        editando:false,
        datoActual:'',
    }),

    methods:{
        edita(){
            this.datoActual = this.dato
            this.editando = true
        },
        guarda(){
            this.editando = false
            this.$emit('guarda')
            
        },
        cancela(){
            this.esteDato = this.datoActual
            this.editando = false
        }
    },
    computed:{
        esteDato:{
            get(){
                return this.dato
            },
            set(val){
                this.$emit('update:dato',val)
            },


        },
    },
}
</script>

<style>

</style>