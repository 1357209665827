<template>
    <v-dialog
            v-model="dialog"
            max-width="600"
            permanent
        >
            <v-card>
                <v-card-title
                    class="headline green lighten-2"
                    primary-title
                >
                    Detalles del usuario "{{ec.username}}"
                </v-card-title>

                <v-card-text class="pt-3 ">
                    <div v-if="cargando">
                        <v-skeleton-loader
                            type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                        ></v-skeleton-loader>
                    </div>

                    <v-row v-if="!cargando" > 
                        <v-col xs="12" sm="6" md="6" lg="6" xl="6" v-for="itm in campos" :key="itm.id">
                            <campo-detalle-usuario
                                :dato.sync="ec[itm.campo]"
                                :etiqueta="itm.etiqueta"
                                :campo="itm.campo"
                                @guarda="guarda"
                            />
                        </v-col>
                        <v-col cols="12">
                            <campo-detalle-usuario
                                :dato.sync="ec.institucionCedula"
                                etiqueta="Escuela (médicos)"
                                campo="institucionCedula"
                                @guarda="guarda"
                            />
                        </v-col>

                        <v-col cols="12">
                            <campo-detalle-usuario
                                :dato.sync="ec.observaciones"
                                etiqueta="Observaciones"
                                campo="observaciones"
                                @guarda="guarda"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-select
                                v-model="ec.roles"
                                :items="rolesTodos"
                                label="Roles"
                                multiple
                                chips
                                deletable-chips
                                :disabled="actualizandoRoles"
                                @change="cambiaRoles()"
                            ></v-select>
                        </v-col>



                        
                    </v-row>

                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    
                    <v-btn
                        color="success"
                        @click="cierra"
                    >
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog>
</template>

<script>
import CampoDetalleUsuario from '@/components/security/CampoDetalleUsuario'
export default {
    mounted(){
        this.getRolesTodos()
    },
    props:{
        dialogUsr:Boolean,
        esteUsuario:Number
    },
    components:{
        'campo-detalle-usuario':CampoDetalleUsuario,
    },
    data:()=>({
        campos:[
            {id:1, campo:'nombres', etiqueta:'Nombres'},
            {id:2, campo:'apellidoPaterno', etiqueta:'Primer apellido'},
            {id:3, campo:'apellidoMaterno', etiqueta:'Segundo apellido'},
            {id:4, campo:'curp', etiqueta:'C.U.R.P.'},
            {id:5, campo:'cedula', etiqueta:'Cédula'},
            {id:6, campo:'especialidad', etiqueta:'Especialidad'},
            {id:7, campo:'correo', etiqueta:'E-Mail'},
            //{id:8, campo:'observaciones', etiqueta:'Observaciones'}
        ],
        rolesOriginales:[],
        rolesTodos:[],
        ec:{},
        cargando:false,

        actualizandoRoles:false,
    }),

    methods:{

        async cambiaRoles(){
            this.actualizandoRoles=true
            let arrA = this.ec.roles
            let arrB = this.rolesOriginales
            let accion = null
            //console.log(original - actual)

            let difference = arrA
                 .filter(x => !arrB.includes(x))
                 .concat(arrB.filter(x => !arrA.includes(x)))[0]

            if (arrA.length - arrB.length < 0){
                accion = 'q'
                //console.log('Quitando '+difference)
            }

            if (arrA.length - arrB.length > 0){
                accion = 'a'
                //console.log('Agregando '+difference)
            }
            //console.log(accion)
            //console.log(difference)
            
            try{
                let rf = await this.$http({
                    method:'GET',
                    url:'/roles/change',
                    params:{usuarioId:this.ec.id, accion:accion, valor:difference}
                })
                console.log(rf.data.root.id)
                this.actualizandoRoles=false
                
                this.carga(this.ec.id)
                
            }catch(err){
                this.carga(this.ec.id)
                this.$store.dispatch('security/revisaError',err)
                this.actualizandoRoles=false
            }
        },


        async carga(id){
            this.cargando = true
            try{
                let eu = await this.$http({
                    method:'POST',
                    url:'/usuarios/consultapaciente',
                    data:{id:id}
                })
                this.ec = eu.data
                this.cargando=false
                this.rolesOriginales = eu.data.roles
                //console.log(this.ec)

            }catch(err){
                this.cargando=false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        cierra(){
            this.dialog=false
        },
        async guarda(){
            try{
                let da = await this.$http({
                    method:'POST',
                    url:'/usuarios/editar',
                    data:this.ec
                })

                if (da.data.estado == 'false'){
                    console.log(da.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede actualizar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                this.carga(this.ec.id)
                this.$emit('actualiza')
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.carga(this.ec.id)
            }
        },
        async getRolesTodos(){
            let rl = await this.$http({
                method:'GET',
                url:'/roles/getRoles'
            })

            //console.log(rl.data)
            this.rolesTodos = rl.data
        }
    },
    computed:{
        dialog:{
            get(){
                return this.dialogUsr
            },
            set(val){
                this.$emit('update:dialogUsr',val)
            },


        },
    },
    watch:{
        async esteUsuario(val){
            if (val >0){
                this.carga(val)
            }
        }
    }

}
</script>

<style>

</style>