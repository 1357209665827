<template>
    <v-dialog
            v-model="dialog"
            max-width="600"
            permanent
        >
            <v-card>
                <v-card-title
                    class="headline green lighten-2"
                    primary-title
                >
                    Nuevo usuario
                </v-card-title>

                <v-card-text class="pt-3 ">
                    <v-form
                        ref="userForm"
                        v-model="validUser"
                    >
                        <v-row no-gutters>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.nombres"
                                    rounded
                                    outlined
                                    dense
                                    @change="cargaUsername()"
                                    label="Nombres"
                                    :rules="[v => !!v || 'Campo necesario']"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.apellidoPaterno"
                                    rounded
                                    outlined
                                    dense
                                    @change="cargaUsername()"
                                    label="Primer apellido"
                                    :rules="[v => !!v || 'Campo necesario']"
                                ></v-text-field>
                            </v-col>

                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.apellidoMaterno"
                                    rounded
                                    outlined
                                    dense
                                    label="Segundo apellido"
                                ></v-text-field>
                            </v-col>

                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-radio-group v-model="ec.sexo" row class="px-1">
                                    <v-radio
                                        label="Masculino"
                                        value="m"
                                    ></v-radio>
                                    <v-radio
                                        label="Femenino"
                                        value="f"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.cedula"
                                    rounded
                                    outlined
                                    dense
                                    :rules="[v => !!v || 'Campo necesario']"
                                    label="Cédula"
                                ></v-text-field>
                            </v-col>


                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.especialidad"
                                    rounded
                                    outlined
                                    dense
                                    :rules="[v => !!v || 'Campo necesario']"
                                    label="Especialidad"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.institucionCedula"
                                    rounded
                                    outlined
                                    dense
                                    label="Escuela (médicos)"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.curp"
                                    rounded
                                    outlined
                                    dense
                                    :rules="[v => !!v || 'Campo necesario']"
                                    label="C.U.R.P."
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.correo"
                                    rounded
                                    outlined
                                    dense
                                    label="Correo electrónico"
                                ></v-text-field>
                            </v-col>
                            
                        </v-row>
                        <hr/>

                        <v-row no-gutters class="pt-5"> 
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.username"
                                    rounded
                                    outlined
                                    dense
                                    label="Nombre de usuario"
                                    @change = "buscaUsuario"
                                    :rules="[
                                        
                                        v => !!v || 'Seleccione un usuario'
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                
                            </v-col>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    dense
                                    v-model="ec.password"
                                    type="password"
                                    rounded
                                    outlined
                                    label="Contraseña"
                                    :rules="[v => !!v || 'Anote una contraseña']"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                <v-text-field
                                    class="px-1"
                                    dense
                                    v-model="ec.password2"
                                    type="password"
                                    rounded
                                    outlined
                                    label="Confirme contraseña"
                                    :rules="[
                                        v => v===ec.password || 'No coinciden',
                                        v => !!v || 'Anote una contraseña'
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    class="px-1"
                                    v-model="ec.observaciones"
                                    rounded
                                    outlined
                                    dense
                                    label="Observaciones"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    
                    <v-btn
                        text
                        color="error"
                        @click="cierra"
                    >
                        Cerrar
                    </v-btn>
                    
                    <v-btn
                        color="success"
                        @click="guarda"
                        :disabled="!validUser"
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog>
</template>

<script>
export default {
    
    props:{
        dialogNew:Boolean,
    },
    data:()=>({
        ec:{
            nombres:'',
            apellidoPaterno:'',
            apellidoMaterno:'',
            sexo:'',
            cedula:'',
            curp:'',
            especialidad:'',
            correo:'',
            username:'',
            password:'',
            password2:'',
            observaciones:'',
        },
        guardando:false,
        validUser:false,
        existeUsuario:false,
    }),

    methods:{
        async buscaUsuario(){
            this.existeUsuario = false
            try{
                let ex = await this.$http({
                    method:'POST',
                    url: '/usuarios/consultaduplicado',
                    data:{username:this.ec.username}
                })
    
                if (ex.data.estado == 'true'){
                    this.existeUsuario = true
                    
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Usuario existente',
                        text: 'El usuario '+this.ec.username+' ya esta en uso.',
                    })
                    this.ec.username=''
    
                }else {
                    this.existeUsuario = false
                }
            }
            catch(err){
                this.$store.dispatch('security/revisaError',err)
            }

            
        },
        cierra(){
            this.$refs.userForm.reset()
            this.dialog=false
        },
        cargaUsername(){
            if (this.ec.nombres && this.ec.apellidoPaterno){
                let primero = this.ec.nombres.split(" ")[0]
                let segundo = this.ec.apellidoPaterno.split(" ")[0]
                this.ec.username = primero+'.'+segundo
                this.ec.username = this.ec.username.toLowerCase()
                this.buscaUsuario()
            }
        },
        async guarda(){
            try{
                let da = await this.$http({
                    method:'POST',
                    url:'/usuarios/crear',
                    data:this.ec
                })
                
                if (da.data.estado == 'false'){
                    
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                } else {
                    this.dialog=false
                    //console.log(da.data.root.id)
                    this.$refs.userForm.reset()
                    this.$emit('guardadoNuevo', da.data.root.id)
                }
                
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        
    },
    computed:{
        dialog:{
            get(){
                return this.dialogNew
            },
            set(val){
                this.$emit('update:dialogNew',val)
            },


        },
    },
    watch:{
        'ec.nombres'(v){
                v?this.ec.nombres=(v.toUpperCase()):null
        },
        'ec.apellidoPaterno'(v){
                v?this.ec.apellidoPaterno=(v.toUpperCase()):null
        },
        'ec.apellidoMaterno'(v){
                v?this.ec.apellidoMaterno=(v.toUpperCase()):null
        },
        'ec.curp'(v){
                v?this.ec.curp=(v.toUpperCase()):null
        },
        'ec.especialidad'(v){
                v?this.ec.especialidad=(v.toUpperCase()):null
        },
    }

}
</script>

<style>

</style>