<template>
    <v-container fluid>
        <v-card>

            <v-toolbar extended dark color="primary">

                <v-btn icon @click="$router.push({path: '/admin'})">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-toolbar-title>
                    <v-toolbar-title>Usuarios</v-toolbar-title>
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn icon @click="dialogNew=true">
                    <v-icon>mdi-account-plus</v-icon>
                </v-btn>

                <template v-slot:extension>
                    <v-text-field
                        v-model="query"
                        label="Buscar"
                        single-line
                        hide-details
                        clearable
                        @click:clear="cargaTodos()"
                    ></v-text-field>
                    <!--
                    <v-btn icon @click="busca()">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    -->
                </template>
            </v-toolbar>


            <v-card-text>
                <v-data-table
                    item-key="id"
                    :loading="loadingPac"
                    loading-text="Buscando... espere"
                    :headers="headers"
                    :items="listUsers"
                    :items-per-page="25"
                    disable-filtering
                    disable-pagination
                    hide-default-footer
                    disable-sort
                >
                    <template v-slot:no-data>
                        <span>No se encontraron resultados...</span>
                    </template>

                    <template v-slot:[`item.enabled`]="{ item }">
                        <v-switch
                            dense
                            hide-details
                            v-model="item.enabled"
                            @change="activa(item.id, item.enabled)"
                        ></v-switch>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            class="mx-1"
                            @click="cargaUsuario(item)"
                        >
                            mdi-account-details
                        </v-icon>

                        <v-icon
                            class="mx-1"
                            small
                            @click="cambiaPassword(item)"
                        >
                            mdi-key
                        </v-icon>
                    </template>

                </v-data-table>


                <div v-if="!loadingPac">
                    <v-pagination
                        circle
                        v-model="page"
                        :length="pageLength"
                        total-visible="7"
                        @input="carga"
                    ></v-pagination>

                    <div class="text-center caption">
                        <b>{{pageDe}}</b> a <b>{{pageA}}</b> de <b>{{total}}</b>
                    </div>
                </div>
                <!-- Ventana para PASSWORD de usuario -->
                <cambia-passwd
                    :dialogPass.sync="abreCambiaPassword"
                    :data="esteUsuario"
                />

                <!-- Ventana para DETALLES de usuario -->
                <detalle-usuario
                    :dialogUsr.sync="abreDetalleUsr"
                    :esteUsuario="esteUsuario.id"
                    @actualiza="carga"
                />

                <!-- Ventana para NUEVO USUARIO -->
                <nuevo-usuario
                    :dialogNew.sync="dialogNew"
                    @guardadoNuevo="guardadoNuevo"
                />
            </v-card-text>
        </v-card>
    </v-container>



</template>

<script>
import CambiaPasswd from '@/components/security/CambiaPassword'
import DetalleUsuario from '@/views/admin/security/detalleUsuario'
import NuevoUsuario from '@/views/admin/security/nuevoUsuario'
export default {
    components:{
        'cambia-passwd':CambiaPasswd,
        'detalle-usuario':DetalleUsuario,
        'nuevo-usuario':NuevoUsuario,
    },
    mounted(){
        this.carga()
    },
    data:()=>({
        headers: [
            {
                text: 'Usuario',
                value: 'username',
                sortable:false,
                width:  "15%"
            },

            {
                text: 'Activo',
                value: 'enabled',
                sortable:false,
                width:  "5%",
                align:'center',
            },
            {
                text: 'Nombre',
                value: 'nombre',
                sortable:false,
            },
            {
                text: 'Especialidad',
                value: 'especialidad',
                sortable:false,
                width:  "20%",
                align:'center',
            },
            {
                text: '',
                align:'center',
                value: 'actions',
                width:  "12%",
                sortable:false,
            },
        ],

        listUsers:[],
        esteUsuario:{},
        loadingPac:false,
        query:'',

        total:0,
        start:0,
        page:1,
        limit:25,

        abreCambiaPassword:false,
        abreDetalleUsr:false,
        dialogNew:false,
    }),
    methods:{
        async activa(id, dato){
            console.log(id)
            console.log(dato)

            try{
                let da = await this.$http({
                    method:'POST',
                    url:'/usuarios/editar',
                    data:{id:id, enabled:dato}
                })

                if (da.data.estado == 'false'){
                    console.log(da.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede actualizar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                this.carga()
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.carga()
            }
        },
        cargaTodos(){
            this.page = 1
            this.query=''
            this.carga()
        },
        busca(){
            this.page = 1
            this.carga()
        },
        async carga(){
            this.loadingPac=true
            this.listUsers=[]
            let params = {
                q:this.query,
            }

            params.page = this.page
            params.start = (this.page -1) * 10
            params.limit = 10

            try {
                let pac = await this.$http({
                    url:'/usuarios/list',
                    method:'GET',
                    params:params
                })
                this.total = pac.data.total
                this.listUsers = pac.data.root
                this.loadingPac=false
            }catch(err){
                this.loadingPac=false
                this.$store.dispatch('security/revisaError',err)
            }

        },
        cambiaPassword(pac){
            this.esteUsuario = pac
            this.abreCambiaPassword=true
        },
        async cargaUsuario(pac){
            this.abreDetalleUsr = true
            this.esteUsuario = pac
        },
        guardadoNuevo(id){
            this.carga()
            this.cargaUsuario({id:id})
        }
    },


    computed: {
        pageLength: function(){
            return (Math.ceil(this.total / 10));
        },
        pageDe: function(){
            let pd = 0
            if (this.total > 0) {
                pd = ((this.page - 1) * 10)+1
            }
            return pd
        },
        pageA: function(){
            let pa = this.page * 10
            if (pa > this.total ){
                pa = this.total
            }
            return (pa)
        },
    },

    watch:{
        query(){
            if (!this.loadingPac){
                this.busca()
            }
        }
    }
}
</script>

<style>

</style>